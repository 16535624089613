import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "meaningful-nouns",
      "style": {
        "position": "relative"
      }
    }}>{`Meaningful Nouns`}<a parentName="h1" {...{
        "href": "#meaningful-nouns",
        "aria-label": "meaningful nouns permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`This conversation revolves around Non-Fungible Tokens for the first time in the guild. We take a look at NounsDAO, their one NFT released every day forever, the governance framework associated with it and consider together what token communities formed around non-fungible assets may look like and what it means to form an open source brand.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ol>
      <li parentName="ol">{`Read through their current proposals to get a sense for `}<a parentName="li" {...{
          "href": "https://nouns.wtf/vote"
        }}>{`governance revolving around NFTs`}</a>{`. `}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://twitter.com/sdimantha/status/1439331675826114560?s=21"
        }}>{`Shawn's thread`}</a>{` provides good context for everything from IP to open brands to subDAOs, which are all discussed throughout the call.`}</li>
    </ol>
    <h3 {...{
      "id": "additional-resources",
      "style": {
        "position": "relative"
      }
    }}>{`Additional Resources`}<a parentName="h3" {...{
        "href": "#additional-resources",
        "aria-label": "additional resources permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <ol>
      <li parentName="ol">{`This article on `}<a parentName="li" {...{
          "href": "https://vitalik.eth.limo/general/2021/03/23/legitimacy.html"
        }}>{`legitimacy`}</a>{` will provide useful context.`}</li>
      <li parentName="ol">{`The NounsDAO monorepo is one of the best there is. The auction house is forked from Zora, the ERC721 from OpenZepellin (with some small but well thought-out and documented changes), the governance from Compound. Take a look at `}<a parentName="li" {...{
          "href": "https://github.com/nounsDAO/nouns-monorepo/blob/master/packages/nouns-contracts/contracts/governance/NounsDAOLogicV1.sol"
        }}>{`this contract in particular`}</a>{` if you're a developer.`}</li>
    </ol>
    <h2 {...{
      "id": "group-work",
      "style": {
        "position": "relative"
      }
    }}>{`Group Work`}<a parentName="h2" {...{
        "href": "#group-work",
        "aria-label": "group work permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`You can follow the work we did together when in discussion `}<a parentName="p" {...{
        "href": "https://www.figma.com/file/m5uqNsC5x0WrY3j29xscgn/Tokens-Guild%3A-NOUNS?node-id=776%3A136"
      }}>{`on this Figma board`}</a></p>
    <Video src="https://www.youtube-nocookie.com/embed/cFs5cAzafFU" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      